import { render, staticRenderFns } from "./withdraw-apply.vue?vue&type=template&id=6f5166b1&scoped=true"
import script from "./withdraw-apply.vue?vue&type=script&lang=js"
export * from "./withdraw-apply.vue?vue&type=script&lang=js"
import style0 from "./withdraw-apply.vue?vue&type=style&index=0&id=6f5166b1&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f5166b1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wxq/workspace/ssl-odat-patient-wechat/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f5166b1')) {
      api.createRecord('6f5166b1', component.options)
    } else {
      api.reload('6f5166b1', component.options)
    }
    module.hot.accept("./withdraw-apply.vue?vue&type=template&id=6f5166b1&scoped=true", function () {
      api.rerender('6f5166b1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/doctor/withdraw-apply.vue"
export default component.exports